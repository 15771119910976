import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import Form from 'form';
import dispatchRerender from 'dispatchRerender';
import FormContext from "formContext";
import PrivacyPolicyModal from "@Components/PrivacyPolicyModal";
import GetRouteByName from 'getRouteByName';
import axios from 'axios';
import NoticeInPage from "@Components/NoticeInPage";

function Register2Step({intl, match}) {
  const headerTitleLeft = intl.formatMessage({id: "Register.Header.Title.Left"});
  const headerTitleRight = intl.formatMessage({id: "Register.Header.Title.Right"});
  const {setAdditionalData, formData, setFormData, handleErrors, renderStep, currentStep} = useContext(FormContext);
  const [modalOpen, setModalOpen] = useState(false);
  const registerConfirmationUrl = GetRouteByName('register-confirmation').url;

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      Promise.all([
        axios.get(`${process.env.REACT_APP_API_PREFIX}/job-categories-list`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/branch-specialisms`),
      ])
        .then(([jobCategoriesListResponse, branchSpecialismsResponse]) => {
          setAdditionalData({
              regions: jobCategoriesListResponse?.data?.regions,
              specialisms: jobCategoriesListResponse?.data?.specialisms,
              jobTypes: jobCategoriesListResponse?.data?.jobTypeList,
              branches: branchSpecialismsResponse?.data,
            }
          );
        });
    }

    dispatchRerender();
    setFormData([]);
  }, []);

  const handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    if (event.target.type === 'checkbox') {
      let checkboxValue = event.target.value;
      if (typeof checkboxValue === 'string' && checkboxValue === 'true') {
        checkboxValue = true;
      }
      value = event.target.checked ? checkboxValue : null;
    }

    // Handle privacy checkbox.
    if ((name === 'accept_privacy_policy') && process.env.REACT_APP_DISPLAY_MODAL_ON_REGISTER_PRIVACY_POLICY === 'true') {
      if (value) {
        setModalOpen(true);
        return;
      }
    }

    setFormData((prev) => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep][name] = value;

      return {
        ...prev
      }
    });
  };

  const onAcceptConsent = () => {
    setFormData((prev) => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep]['accept_privacy_policy'] = 'yes';
      return {
        ...prev
      }
    });
    setModalOpen(false);
  };

  const modalFooter = () => (
    <>
      <button onClick={onAcceptConsent} className="button button--m button--filled button--full-width">
        {intl.formatMessage({id: 'Register.Section.PersonalDataProcessing.Checkbox.PrivacyPolicy.Modal.Button'})}
      </button>
    </>
  );

  const handleSubmit = event => {
    event.preventDefault();

    renderStep('next');
    return;
    axios.post(`${process.env.REACT_APP_API_PREFIX}/signup`, formData[currentStep])
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            eventCategory: "[H] Candidate",
            eventAction: "[H] Soft Conversion",
            eventLabel: "[H] Profile Created",
            event: "eventTracker"
          });

          window.dataLayer.push({event_params: null});
          window.dataLayer.push({
            event: 'interaction',
            event_params: {
              'event_name': 'account_created',
              'user_type': 'talent'
            }
          });

          window.location = registerConfirmationUrl;
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  };

  const onCloseModal = () => {
    setModalOpen(false)
  };

  const renderModal = () => {
    if (!modalOpen) {
      return '';
    }
    return (
      <PrivacyPolicyModal
        title={intl.formatMessage({id: 'Register.Section.PersonalDataProcessing.Checkbox.PrivacyPolicy.Modal.Title'})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={false}
      />
    );
  };

  const noticeSettings = {
    type: 'warning',
    message: intl.formatMessage({id: "Cutover.Register"})
  }

  return (
    <>
      <Helmet>
        <header className="header header--text bg-variant-brand-primary"/>
      </Helmet>
      <div className="header header--text bg-variant-brand-primary">
        <div className="header__wrapper wrapper">
          <div className="header__content header__content--l content-block header__content--full-width">
            <h1 className="content-block__title">
              <span className="content-block__title-top">{headerTitleLeft}</span>
              <span className="content-block__title-bottom text--emphasis">{headerTitleRight}</span>
            </h1>
          </div>
        </div>
      </div>
      { process.env.REACT_APP_COUNTRY === "it" &&
        <div className="block block--xs">
          <div className="wrapper block__wrapper">
            <NoticeInPage settings={noticeSettings} style={{maxWidth: '100%'}} />
          </div>
        </div>
      }
      <Form name='register-2-step' handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit} />
      {process.env.REACT_APP_DISPLAY_MODAL_ON_REGISTER_PRIVACY_POLICY === 'true' && renderModal()}
    </>
  )
}

export default injectIntl(Register2Step);
